import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './Header.module.css';
import { Link } from 'react-router-dom';

function HamburgerMenu(props) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  console.log(props.userpic);  
  return (
    <nav className={`navbar topBar navbar-expand-lg navbar-light bg-light ${styles.topBar}`}>
      <button className="navbar-toggler" type="button" onClick={toggleMenu} aria-controls="navbarNav" aria-expanded={isOpen} aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      
      <a className={`${styles.navbarBrand} navbar-brand `} href="#">Netbrava Investimentos</a>

      <div className={`${isOpen ? 'show' : ''} collapse navbar-collapse`} id="navbarNav">
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link className="nav-link active" to="/">Home</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/dashboard">Dashboard</Link>
          </li>
          <li className="nav-item">
          <Link className="nav-link" to="#">Other</Link>
          </li>
        </ul>
      </div>

      <img src={props.userpic} alt='profile'/>
    </nav>
  );
}

export default HamburgerMenu;