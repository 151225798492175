import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { HomePage } from './pages/Home';
import { Dashboard } from './pages/Dashboard';
import { useState } from 'react';
import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  const [user, setUser] = useState({ logged: false });
  const router = createBrowserRouter([
    { path: '/', element: <HomePage user={user} setUser={setUser} /> },
    { path: '/dashboard', element: <Dashboard user={user} setUser={setUser} /> }
  ]);
  
  return <RouterProvider router = {router} user={user} />

}

export default App;
