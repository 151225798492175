
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { Header } from '../components/Header';
import { useState, useEffect } from 'react';
import { Balance } from '../components/Balance.js';
import styles from './Dashboard.module.css';


export function Dashboard(props) {
    const navigate = useNavigate();
    const [ balanceData , setBalanceData ] = useState([]);
    const email = props.user.email;

    useEffect(() => {
        fetch(`https://api.netbrava.com/v1/balance.php?t=${Date.now()}&e=${email} `)
        .then(response => response.json())
        .then(balance => {
            setBalanceData(balance.balance);
        });
    }, [email]);

    if (!props.user.logged) {
        // alert('You need to log in first');
        navigate('/');
        return (<div>Not allowed</div>);
    }
    console.log('User: ', props.user.email);

    return (<div className={styles.content}>
        <Header user={props.user} setUser={props.setUser}/>
        
        <GoogleOAuthProvider clientId="741554202409-vkk29bprbqdrgp3mv4l8dd0sm8b0ekji.apps.googleusercontent.com">
            <Balance balanceData={balanceData} fallbackText='Loading' />
        </GoogleOAuthProvider>
    </div>);
}