import { Header } from '../components/Header';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

export function HomePage(props) {
    const navigate = useNavigate();

    
    console.log('hi! ');


    return (
        <div>
            <Header user={props.user} setUser={props.setUser}/>
            <br/><br/><br/>
            
            <GoogleOAuthProvider clientId="741554202409-vkk29bprbqdrgp3mv4l8dd0sm8b0ekji.apps.googleusercontent.com">
                <center>
                <GoogleLogin
                    onSuccess={credentialResponse => {
                        console.log(credentialResponse);
                        let user = jwtDecode(credentialResponse.credential);
                        props.setUser({ email: user.email, name: user.name, picture: user.picture, logged: true });
                        navigate('/dashboard');
                    }}
                    onError={() => {
                        console.log('Login Failed');
                    }}
                
                />
                </center>

            </GoogleOAuthProvider>
        </div>
    );
}

export default HomePage;