import styles from './Header.module.css';
import HamburgerMenu from './HamburgerMenu'; 

export function Header(props) {
    console.log('head:', props);
    return (
        <HamburgerMenu className={styles.topBar} userpic={ props.user.picture }/>
        );
}

export default Header;