
import styles from './Balance.module.css';


/*

Buscar la ultima fila de cada año y agregarle esto:
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom: 1px solid black;
    margin-bottom: 30px;

*/

export function Balance(props) {
    let year = '0';
    const formatter = new Intl.DateTimeFormat('pr', { month: 'short' });
    let nf = new Intl.NumberFormat('es-AR');

    let totals = { items: [], total: 0, total_usd: 0, investor: '' };
    
    if (props.balanceData.length > 0) {
        props.balanceData.forEach((payment) => {
            if (typeof totals.items[payment.real_state_id] === 'undefined') {
                totals.items[payment.real_state_id] = {
                    total: 0,
                    total_usd: 0,
                    id: payment.real_state_id,
                    name: payment.real_state
                };
            }
            totals.items[payment.real_state_id].total += Number(payment.your_payment);
            totals.items[payment.real_state_id].total_usd += Number(payment.your_payment_usd);
            totals.total += Number(payment.your_payment);
            totals.total_usd += Number(payment.your_payment_usd);
            totals.investor = payment.investor;
        });
    }

    console.log(totals);

    return(<div>
        { (totals.total >= 0) && (
            <div>
                <h1>Total investido até {(new Date()).toLocaleDateString('es-AR')}
                    <div className={styles.investor}>{totals.investor}</div>
                </h1>
                <h3 className={styles.totals}> 
                    <div className={styles.half}>
                        <div className={`${styles.summary} ${styles.simpleAmount}`}>
                        <div className={styles.amountTitle}>
                            Reales
                        </div>
                        <span>
                            {nf.format(Number(totals.total).toFixed(0))}
                        </span>
                        <sup className={styles.cents}>,<u><small><small>
                            {Number(totals.total).toFixed(2).slice(-2)}
                        </small></small></u>
                        </sup>
                        </div>
                    </div>
                    <div className={styles.half}>
                        <div className={`${styles.summary} ${styles.simpleAmount}`}>
                        <div className={styles.amountTitle}>
                            Dolares aprox.
                        </div>
                        <span>
                            {nf.format(Number(totals.total_usd).toFixed(0))}
                        </span>
                        <sup className={styles.cents}>,<u><small><small>
                            {Number(totals.total_usd).toFixed(2).slice(-2)}
                        </small></small></u>
                        </sup>
                        </div>
                    </div>

                </h3>

                <div className={styles.summary}>
                    <div className={`${styles.balanceRow} ${styles.summaryHeader}`}>
                        Quadro resumo
                    </div>
                    { 
                        (totals.total > 0) && (
                            totals.items.map((total) => (
                                <div className={styles.balanceRow}>
                                <div>{ total.name }</div>
                                <div className={styles.amount}>
                                            <b>
                                            <small><small>R$ </small></small>
                                            <span className={styles.integer}>
                                            {nf.format(Number(total.total).toFixed(0))}
                                            </span>
                                            <sup className={styles.cents}>,<u><small>
                                            {Number(total.total).toFixed(2).slice(-2)}</small></u>
                                            </sup></b>
                                            <br/>
                                            <small>
                                            <small><small>USD </small></small>
                                            {nf.format(Number(total.total_usd).toFixed(0))},
                                            <sup className={styles.cents}><small><u>
                                            {Number(total.total_usd).toFixed(2).slice(-2)}</u></small>
                                            </sup>
                                            </small>
                                        </div>
                                
                                </div>
                            )) 
                        ) 
                    }
                    <div className={`${styles.balanceRow} ${styles.summaryFooter}`}>
                    </div>
                </div>
            </div>
        ) }

        { (props.balanceData.length === 0) && (<p>{props.fallbackText}</p>) }

        <div className={styles.balanceGroup}>
        { (props.balanceData.length > 0) && (
             props.balanceData.map((payment) => 
                (
                <div>
                    { (year !== payment.due_date.slice(0,4)) && (year = payment.due_date.slice(0,4)) && (<div className = { styles.yearRow }>Imputações { year }</div>) } 
                    { year === payment.due_date.slice(0,4) && (
                        <div className={styles.balanceRow}>
                            <div className={styles.date}>
                                <div className={styles.day}>
                                    {payment.due_date.slice(-2)}
                                </div>
                                <div className={styles.month}>
                                    {formatter.format(new Date(payment.due_date))}
                                </div>
                            </div>
                            <div className={styles.realState}>
                                <b>{payment.details.split('(')[0]}</b><br/>
                                <small><i>{payment.details.split('(')[1].slice(0,-1)} ({payment.your_part})</i></small>
                            </div>
                            <div className={styles.amount}>
                                <b>
                                <small><small>R$ </small></small>
                                <span className={styles.integer}>
                                {nf.format(Number(payment.your_payment).toFixed(0))}
                                </span>
                                <sup className={styles.cents}>,<u><small>
                                {Number(payment.your_payment).toFixed(2).slice(-2)}</small></u>
                                </sup></b>
                                <br/>
                                <small>
                                <small><small>USD </small></small>
                                {nf.format(Number(payment.your_payment_usd).toFixed(0))},
                                <sup className={styles.cents}><small><u>
                                {Number(payment.your_payment_usd).toFixed(2).slice(-2)}</u></small>
                                </sup>
                                </small>
                            </div>
                        </div>
                    )}
                </div>
                )
            )
        ) }
        </div>
    </div>);

}

